<template>
  <form @submit.prevent="save()">
    <!-- <div class="form-group"> -->
    <!--   <label for>Area*</label> -->
    <!--   [> <ltg-area-select :areaId.sync="reg.area_id"></ltg-area-select> <] -->
    <!--   <SelectArea :required="true" v-model="reg.area_id"></SelectArea> -->
    <!-- </div> -->
    <div class="form-group">
      <label for>Nombre*</label>
      <input type="text" v-model="reg.name" class="form-control" required />
    </div>
    <!-- <div class="form-group" v-show="!reg.id"> -->
    <!--   <label for>Presupuesto</label> -->
    <!--   <app-input-money -->
    <!--     ref="inputMoney" -->
    <!--     :quantity.sync="reg.max" -->
    <!--     :moneyCode.sync="reg.money_code" -->
    <!--   ></app-input-money> -->
    <!-- </div> -->
    <div class="form-group">
      <label for>Descripción</label>
      <textarea
        rows="3"
        class="form-control"
        v-model="reg.description"
      ></textarea>
    </div>
    <div class="form-group">
      <label for>Tipo</label>
      <TypeSelect :required="true" v-model="reg.type_id"></TypeSelect>
    </div>
    <div class="form-group">
      <button :disabled="sending" class="btn btn-primary" type="submit">
        Guardar
      </button>
    </div>
  </form>
</template>

<script>
import TypeSelect from "./TypeSelect";
// import SelectArea from "../areas/Select";
import { AdminService } from "../AdminService";

export default {
  components: {
    TypeSelect
    //SelectArea
  },
  data() {
    return {
      reg: {},
      sending: false
    };
  },
  methods: {
    loadReg(reg) {
      this.reg = JSON.parse(JSON.stringify(reg));
    },
    reset() {
      this.reg = {};
      this.reg.area_id = this.$store.state.auth.area_id;
      this.$refs.inputMoney.setDefault();
      this.sending = false;
    },
    save() {
      this.sending = true;
      AdminService.saveReason(this.reg).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style></style>
